@import "../../../styles/constants/colors";

.smartTableHeaderCell {
  min-width: 40px;
  padding: 10px 20px;
  border-left: 1px solid rgba($color: $smart-text-neutral, $alpha: 0.5);
  background-color: $color-white;
  font-weight: normal;
}

.smartTableHeaderCellNoPadding {
  padding: 0;
}

.smartTableHeaderCellAction {
  cursor: pointer;
}

.smartTableHeaderCellTextLeft {
  text-align: left;
}

.smartTableHeaderCellTextCenter {
  text-align: center;
}

.smartTableHeaderCellTextRight {
  text-align: right;
}

.smartTableHeaderCellFirst {
  border-left: none;
}

.smartTableHeaderCellLast {
  border-right: 1px solid rgba($color: $smart-text-neutral, $alpha: 0.5);
}

.smartTableHeaderCellDashed {
  border-left-style: dashed;
}

.smartTableHeaderCellLastDashed {
  border-right-style: dashed;
}
