.pushRight {
  margin-right: auto;
}

// hard overrides of the default header props
.notificationsButtonLink {
  display: flex !important;
  width: auto !important;
  align-items: center;
  margin-right: 10px !important;
  cursor: pointer;
  text-decoration: none !important;

  &:hover {
    box-shadow: none !important;
    background-color: transparent !important;
  }
}
