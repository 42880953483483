@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";

.stackedBarChart {
  width: 240px;

  @media #{$smart-mobile} {
    margin: 40px 0;
  }
}

.stackedBarChartContainer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.stackedBarChartItem {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding-right: 2px;
  border-left: 1px solid $smart-text-neutral;
  color: $smart-text-neutral;
  white-space: nowrap;
}

.stackedBarChartBar {
  width: 100%;
  height: 20px;
}

.stackedBarChartLabel {
  display: flex;
  height: 40px;
  align-items: flex-start;
  padding-left: 10px;
  color: $smart-text-neutral;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  // border-right: 1px solid #1d1919;

  sup {
    margin-right: 4px;
    color: var(--text-neutral);
    font-family: "Source Sans Pro", sans-serif;
    font-size: 8px;
    font-weight: 400;
    text-align: left;
  }

  .stackedBarChartInfo {
    display: flex;
    height: 75%;
    align-items: center;
    padding-left: 5px;
    border-left: 1px solid $color-grey-3;
    margin-left: 5px;
  }

  .stackedBarChartIcon {
    width: 20px;
    height: 20px;
  }
}
