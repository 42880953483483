@import "../../styles/constants/colors";

.smartTable {
  border-collapse: collapse;
  color: $smart-text-neutral;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.smartTableFrozen {
  position: relative;
  overflow: scroll;
  max-width: 700px;
  max-height: 20em;
  box-shadow: inset -7px 0 9px -7px $contrast-light;

  table {
    border: none;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    white-space: nowrap;
  }

  table thead th {
    position: sticky;
    z-index: 1;
    top: 0;
  }

  table tbody {
    th {
      position: sticky;
      z-index: 1;
      left: 0;
      height: 100%;
      padding: 0;
      background: $color-white;
      font-weight: unset;
      box-shadow: -10px 0 15px 10px $contrast-light;
      clip-path: polygon(0% 0%, 120% 0%, 120% 100%, 0% 100%);
      border-right: 1px solid rgba($color: $smart-text-neutral, $alpha: 0.5);
    }

    td:nth-child(2) {
      border-left: unset;
    }
  }

  table thead th {
    &:first-child {
      position: sticky;
      z-index: 2;
      left: 0;
      background: $color-white;
      border-right: 1px solid rgba($color: $smart-text-neutral, $alpha: 0.5);
      box-shadow: -10px 0 15px 10px $contrast-light;
      clip-path: polygon(0% 0%, 120% 0%, 120% 100%, 0% 100%);
    }

    &:nth-child(2) {
      border-left: unset;
    }
  }
}
