@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";

.SmartStudentReportingCoursesWrapper {
  .SmartStudentReportingCoursesTableWrapper {
    padding: 40px 30px;

    @media #{$smart-mobile} {
      padding: 20px 10px;
      background-color: $smart-background-color-lozenge;
    }
  }
}
