.wrapper,
.wrapperLast {
  width: 100%;
  padding-bottom: 20px;
}

.wrapperLast {
  padding-bottom: 0;
  margin-top: 50px;
}

.sectionTitle {
  font-weight: bold;
}
