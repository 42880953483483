@import "../../styles/constants/colors";

.statisticalLozengesPill {
  display: flex;
  min-width: 100px;
  height: 60px;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 30px;
  background-color: $color-white;
  border-radius: 30px;
  color: $smart-text-neutral;

  p {
    font-size: 21px;
    font-weight: normal;
  }
}
