@import "../../../../../styles/constants/colors";

.filterMobile {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 10px 0 10px;
  background-color: $smart-background-color-lozenge;

  .filterMobileContainer {
    position: relative;

    .filterMobileDot {
      position: absolute;
      top: -13px;
      right: -8px;
    }
  }

  .filterIcon {
    width: 40px;
    height: 40px;
  }
}

.sortAndFilterSwitch {
  padding-top: 20px;
}

.sortAndFilterRadio {
  padding: 20px 0;
}

.clearFilterContainer {
  display: flex;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid $smart-card-border-color;
  font-size: 21px;

  .clearFilterText,
  .clearFilterLink {
    display: flex;
    flex-grow: 1;
  }

  .clearFilterText {
    align-items: center;
    justify-content: flex-start;
  }

  .clearFilterLink {
    align-items: center;
    justify-content: flex-end;

    a {
      color: $smart-text-neutral;
    }
  }
}
