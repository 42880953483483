.smartTableCellBandWrapper {
  width: 20px;
  height: 100%;
}

.smartTableCellBand {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 20px;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-blend-mode: multiply;
}

.smartTableCellBandText {
  padding-left: 20px;
  text-transform: capitalize;
}

.smartTableCellBandTextNA {
  padding-left: 0;
}
