.questionItem {
  display: flex;
  width: 100%;
  max-width: 200px;
  align-items: center;
}

.questionItemLink {
  display: block;
  width: 100%;
  max-width: 50px;
}

.questionItemValue {
  letter-spacing: 3px;
}
