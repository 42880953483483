@import "../../../styles/constants/colors";

.smartTableRow,
.smartTableRowOdd {
  height: 40px;
}

.smartTableRow {
  background-color: transparent;
}

.smartTableRowOdd {
  background-color: rgba($color: $smart-background-color-lozenge, $alpha: 0.4);
}
