.row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.columnLeft,
.columnRight,
.columnIcon,
.columnData {
  display: flex;
  padding: 10px 0;
}

.columnLeft {
  width: 170px;
  min-width: 170px;
}

.columnRight {
  flex-flow: row wrap;
  align-items: center;
  column-gap: 15px;

  sup {
    font-size: 10px;
  }
}

.columnIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.columnData {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  div:first-child {
    display: flex;
    width: 40px;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
}
