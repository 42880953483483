@import "../../../styles/constants/colors";
@import "../../../styles/constants/breakpoints.scss";

.container {
  position: sticky;
  z-index: 1;
  top: 0;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  padding: 5px 2px;
  background: $color-white;
  color: $color-black;
  font-size: 16px;
  font-weight: 600;

  @media #{$smart-desktop} {
    height: 60px;
    padding: 0 10px;
    font-size: 21px;
    font-weight: 400;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $smart-accent-color-lighter;
      content: "";
      opacity: 0.5;
    }
  }

  &.shadow {
    box-shadow: 0 10px 20px -5px $contrast-light;
  }

  .icon {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      transform: rotate(180deg);
    }

    @media #{$smart-mobile} {
      padding: 10px;
    }

    svg {
      height: 40px;
    }
  }

  .title {
    display: flex;
    flex-grow: 2;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
