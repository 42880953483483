@import '../../../styles/constants/breakpoints';

.tableContainerFixedWidth {
  @media #{$smart-desktop} {
    max-width: fit-content;
  }
}

.tableContainerFullWidth {
  display: flex;
  flex-direction: column;
}

.tableWrapper {
  @media #{$smart-desktop} {
    display: flex;
    max-width: 1024px;
    justify-content: center;
    margin: 0 auto;
  }
}
