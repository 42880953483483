@import "../../styles/constants/colors";

.wrapper {
  display: flex;
  width: 100%;
  height: 200px;
  align-items: center;
  justify-content: center;
}

.loadingSpinner {
  width: 120px;
  height: 120px;
  border: 10px solid $smart-background-color-lozenge;
  border-top: 10px solid $smart-accent-color;
  animation: spin 2s linear infinite;
  border-radius: 50%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
