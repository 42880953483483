@import "../../../styles/constants/colors";

.smartTableCell {
  position: relative;
  min-width: 40px;
  max-height: 40px;
  padding: 0 20px;
  border-left: 1px solid rgba($color: $smart-text-neutral, $alpha: 0.5);

  a {
    color: $smart-link-color-normal;

    &:hover {
      color: $smart-link-color-darker;
    }
  }
}

.smartTableCellInner {
  display: block;
  overflow: hidden;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;

  sup {
    font-size: 10px;
  }
}

.smartTableCellTextLeft {
  text-align: left;
}

.smartTableCellTextCenter {
  text-align: center;
}

.smartTableCellTextRight {
  text-align: right;
}

.smartTableCellFirst {
  border-left: none;
}

.smartTableCellLast {
  border-right: 1px solid rgba($color: $smart-text-neutral, $alpha: 0.5);
}

.smartTableCellDashed {
  border-left-style: dashed;
}

.smartTableCellLastDashed {
  border-right-style: dashed;
}
