@import "../../styles/constants/colors";

.secondLevelTabsItem {
  margin: 0 24px;
  cursor: pointer;

  span {
    display: block;
    text-align: center;
  }
}

.secondLevelTabsItemLabel,
.secondLevelTabsItemLabelActive {
  padding-top: 5px;
  padding-bottom: 10px;
  color: $smart-text-neutral;
  font-size: 16px;
}

.secondLevelTabsItemLabel {
  border-bottom: 2px solid transparent;
  font-weight: normal;

  &:hover {
    border-color: $smart-text-neutral;
  }
}

.secondLevelTabsItemLabelActive {
  border-bottom: 2px solid $smart-text-neutral;
  font-weight: 600;
}
