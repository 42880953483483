@import "../../styles/constants/colors";

.smartCard {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  background-color: $color-white;
}

.smartCardApplyBorder {
  border-bottom: 1px solid $smart-card-border-color;
}

.smartCardApplyBorder:only-child,
.smartCardApplyBorder:last-of-type {
  border-bottom: none;
}
