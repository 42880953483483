@import "../../../styles/constants/breakpoints.scss";
@import "../../../styles/constants/colors";

.statisticsArea {
  width: 100%;
  padding: 40px 30px;
  background-color: $smart-background-color-lozenge;

  @media #{$smart-mobile} {
    padding: 20px 10px;
    background: $color-white;
  }
}

.breadcrumb {
  padding: 0 40px 40px 40px;
}

.lozengesWrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  margin: 0 auto;
}

.statisticsItem {
  display: flex;
  flex: 0 0 30%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    display: block;
    padding-top: 10px;
    color: $smart-text-neutral;
  }

  .memoryStrengthIcon {
    display: flex;
    width: 40px;
    height: 40px;
    flex: 0 0 25%;
    margin-right: 10px;
  }

  .memoryStrengthComponent {
    display: flex;
    flex: 0 0 75%;

    div {
      margin-right: 10px;
      font-size: 21px;
    }
  }
}

.iconNormal {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
