@import "../../../../styles/constants/colors";

.lozenges {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 40px 0;
  background-color: $smart-background-color-lozenge;
}

.lozengesWrapper {
  display: flex;
  width: 100%;
  max-width: 1244px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 2rem;
  row-gap: 40px;
}

.lozengesItem {
  display: flex;
  flex: 0 0 30%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    display: block;
    padding-top: 10px;
    color: $smart-text-neutral;
    white-space: nowrap;
  }

  p {
    margin-left: 20px;
  }
}

.iconStyle {
  width: 40px;
  height: 40px;
}
