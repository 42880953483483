@import "../../styles/constants/colors";

.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: none;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-color: #7878784d;
  opacity: 0;
  transition: opacity 0.3s;
}

.overlayRight {
  flex-direction: row-reverse;
}

.main,
.mainRight {
  position: fixed;
  z-index: 1001;
  top: 0;
  display: block;
  width: 100%;
  max-width: 500px;
  height: 100vh;
  background-color: $color-white;
}

.main {
  left: -500px;
  transition: left 0.3s;
}

.mainRight {
  right: -500px;
  transition: right 0.3s;
}

.enabledMain {
  left: 0;
}

.enabledMainRight {
  right: 0;
}

.enabledOverlay {
  display: block;
  opacity: 1;
}

@keyframes customtest {
  0% {
    transform: translateX(-500px);
  }

  100% {
    transform: translateX(-500px);
  }
}

.side {
  flex: 1;
}
