@import "../../../../styles/constants/colors";

.sortAndFilterSwitch {
  padding-top: 20px;
}

.sortAndFilterRadio {
  padding: 20px 0;
}

.clearFilterContainer {
  display: flex;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid $smart-card-border-color;
  font-size: 21px;

  .clearFilterText,
  .clearFilterLink {
    display: flex;
    flex-grow: 1;
  }

  .clearFilterText {
    align-items: center;
    justify-content: flex-start;
  }

  .clearFilterLink {
    align-items: center;
    justify-content: flex-end;

    a {
      color: $smart-text-neutral;
    }
  }
}
