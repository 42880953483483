@import "../../styles/constants/colors";

.wrapper {
  display: flex;
  width: 100%;
  min-width: 100px;
  align-items: center;
  justify-content: center;

  .track {
    overflow: hidden;
    width: 100%;
    height: 10px;
    background-color: none;
    border-radius: 5px;
    box-shadow: 0 0 0 1px $smart-accent-color;

    .bar {
      height: 100%;
      background-color: $smart-accent-color;
    }
  }
}
