@import "../../../../styles/constants/colors";

.wrapper,
.wrapperLast {
  width: 100%;
  padding-bottom: 20px;
  color: $smart-text-neutral;
}

.wrapperLast {
  padding-bottom: 0;
  margin-top: 30px;
}

.sectionTitle {
  margin-bottom: 10px;
  font-weight: bold;
}

.questionsWrapper {
  display: flex;
  width: 100%;
  max-width: 500px;
  flex-flow: row wrap;
  padding: 15px 0;
  row-gap: 10px;
}

.questionItem {
  display: flex;
  width: 100%;
  max-width: 200px;
  align-items: center;
}

.questionItemLink {
  display: block;
  width: 100%;
  max-width: 50px;
}

.questionItemValue {
  letter-spacing: 3px;
}
