@import "../../styles/constants/colors";

.tabItem {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  margin-right: 10px;
  background-color: map-get($secondary-colors, hub_light_grey);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: $smart-text-neutral;

  .mainWrapper {
    display: flex;
    height: 100%;
    align-items: flex-end;
    padding: 15px;
    cursor: pointer;
    text-decoration: none;
  }

  &.active {
    background-color: $color-white;
  }

  .leftWrapper {
    .tabItemTitle {
      display: block;
      margin-top: 0.5em;
      font-size: 1.4rem;
    }

    .tabItemType {
      font-size: 0.8em;
      text-transform: uppercase;
    }
  }

  .rightWrapper {
    display: flex;
    align-items: center;
    margin-left: 5em;
    color: map-get($primary-colors, primary);

    .tabItemMoreIcon {
      line-height: 0.8rem;
    }

    .tabItemMoreLabel {
      margin-right: 10px;
    }

    svg {
      width: 15px;
      height: 15px;
      margin-left: 6px;
      fill: map-get($primary-colors, primary);
      font-size: initial;
      transition: transform 0.3s;
    }

    &.expanded {
      svg {
        transform: rotate(270deg);
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}
