@import "../../../../styles/constants/breakpoints";
@import "../../../../styles/constants/colors";

.Wrapper {
  width: 100%;
  padding: 40px 0;

  .Row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 1px;
    color: $smart-text-neutral;
    font-size: 16px;

    .TitleColumn {
      width: 20%;
      padding: 10px;
      font-weight: 600;

      @media #{$smart-mobile} {
        width: 90%;
      }

      a {
        color: $smart-link-color-normal;
        text-decoration: underline;

        &:hover {
          color: $smart-link-color-darker;
        }
      }
    }

    .EmptyColumn {
      width: 20%;
      padding: 10px;

      @media #{$smart-mobile} {
        width: 10%;
      }
    }

    .DataColumn {
      display: flex;
      width: 20%;
      padding: 10px;

      @media #{$smart-mobile} {
        width: 50%;
      }
    }
  }
}
