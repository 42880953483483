@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";

.wrapper {
  display: flex;
  height: 40px;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 40px;

  @media #{$smart-mobile} {
    margin: 20px 0;
  }

  .element {
    display: flex;
    align-items: center;
    color: $smart-text-neutral;

    .elementLink {
      color: $smart-link-color-normal;
      text-decoration: underline;

      &:hover {
        color: $smart-link-color-darker;
      }
    }

    p {
      font-weight: 600;
    }
  }

  .elementIcon {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    margin: 0 10px;

    .iconArrow {
      height: 20px;
    }
  }
}
