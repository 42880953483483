@import '../../../styles/constants/breakpoints.scss';
@import '../../../styles/constants/colors.scss';

@keyframes slideIn {
  from {
    opacity: 0;
    bottom: -60px;
  }
  to {
    opacity: 1;
    bottom: 76px;
  }
}

.courseNav {
  background-color: var(--product-color);

  &.noLogo {
    .courseLogo {
      display: none;
    }

    ul {
      @media only screen and (min-width: 1024px) {
        justify-content: flex-start;
      }
    }
  }

  &.noLogoOnMobile {
    .courseLogo {
      @media only screen and (max-width: 1023px) {
        display: none;
      }
    }
  }

  .courseLogo,
  .courseLinks {
    background-color: var(--product-color);

    &:global(.col) {
      max-width: 100%;
      flex-basis: 100%;

      @media only screen and (min-width: 1024px) {
        flex-basis: 50%;
        max-width: 50%;
      }
    }

    @media only screen and (min-width: 1024px) {
      height: 60px;
    }
  }

  .courseLogo {
    height: 60px;
    text-align: center;

    @media only screen and (min-width: 1024px) {
      align-items: flex-start;
    }

    img {
      height: 40px;
      margin: 10px 0 0 0;
      max-width: 100%;
    }

    .productLogoDark {
      filter: brightness(0);
    }

    .productLogoWhite {
      filter: brightness(0) invert(1);
    }
  }

  .courseLinks {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-right-width: 0;
    border-left-width: 0;
    z-index: 101;

    @media only screen and (min-width: 1024px) {
      position: static;
      bottom: auto;
      left: auto;
      right: auto;
      background-color: transparent;
      border-right-width: 0.625rem;
      border-left-width: 0.625rem;
    }

    ul.hiddenLinks {
      position: fixed;
      bottom: 76px;
      width: 100%;
      text-align: left;
      padding: 0;
      animation-name: slideIn;
      animation-duration: 300ms;
      background-color: var(--product-color, $color-white);
      z-index: -1;

      li {
        width: 100%;
        padding: 9px 30px 8px 50px;
        border-bottom: 1px solid $color-panel-background-light;

        a {
          display: inline-block;
          line-height: 40px;
          height: auto;

          &.selected {
            border-color: transparent;
          }

          .check {
            margin-left: -40px;
          }
        }

        svg, a {
          float: left;
        }
      }

      li + li {
        margin: 0;
      }
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 7px;
    padding-bottom: 9px;
    box-shadow: 0 -10px 20px -5px $contrast-light;

    @media only screen and (min-width: 1024px) {
      justify-content: flex-end;
      box-shadow: none;
    }

    .more svg {
      border-radius: 50%;
      box-shadow: inset 0 0 0 1.5px transparent;
      border: 1px solid;
    }

    &.moreVisible {
      > li:last-child {
        a:not(.selected) {
          &:hover {
            border-color: transparent;
          }
        }
      }
    }
  }

  li + li {
    margin-left: 4%;
  }

  a {
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.2s;
    color: var(--product-contrast-color);
    font-size: 14px;
    height: 60px;

    &:hover,
    &.selected {
      color: var(--product-contrast-color);
      border-color: var(--product-contrast-color);
    }

    @media only screen and (min-width: 1024px) {
      flex-direction: row;
      font-size: 16px;
      height: auto;
    }
  }
}
