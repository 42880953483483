@import "../../styles/constants/colors";

.smartBadgeContainer {
  display: flex;
}

.smartBadgeContainerBadge,
.smartBadgeContainerBadgeDeveloping,
.smartBadgeContainerBadgeExtending,
.smartBadgeContainerBadgeSecure {
  padding: 4px 15px;
  border-radius: 5px;
  color: $smart-text-neutral;
  font-size: 8pt;
  font-weight: 50;
}

.smartBadgeContainerBadgeDeveloping {
  background-color: rgba($color: $smart-dse-developing, $alpha: 0.3);
}

.smartBadgeContainerBadgeExtending {
  background-color: rgba($color: $smart-dse-extending, $alpha: 0.3);
}

.smartBadgeContainerBadgeSecure {
  background-color: rgba($color: $smart-dse-secure, $alpha: 0.3);
}
