@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints";

.filters {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: flex-start;
  padding: 0 30px;
  padding-top: 20px;
  gap: 40px;
}

.filterMobile {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 10px 0 10px;
  background-color: $smart-background-color-lozenge;

  .filterMobileContainer {
    position: relative;

    .filterMobileDot {
      position: absolute;
      top: -13px;
      right: -8px;
    }
  }

  .filterIcon {
    width: 40px;
    height: 40px;
  }
}

.table {
  padding: 0 30px;
  margin-top: 10px;

  @media #{$smart-mobile} {
    padding: 20px 10px;
    margin-top: unset;
    background-color: $smart-background-color-lozenge;
  }

  a {
    color: $smart-link-color-normal;
    text-decoration: underline;

    &:hover {
      color: $smart-link-color-darker;
    }
  }
}
