@import "../../styles/constants/colors";

.dot {
  width: 10px;
  height: 10px;
  margin: 10px;
  animation: pink-dot-pulse 2s ease-in;
  background-color: rgba($smart-pink-dot, 1);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba($smart-pink-dot, 1);
  transform: scale(1);
}

@keyframes pink-dot-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($smart-pink-dot, 0.7);
    transform: scale(0.95);
  }

  70% {
    box-shadow: 0 0 0 10px rgba($smart-pink-dot, 0);
    transform: scale(1);
  }

  100% {
    box-shadow: 0 0 0 0 rgba($smart-pink-dot, 0);
    transform: scale(0.95);
  }
}
