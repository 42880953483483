@import "../../../../styles/constants/breakpoints";
@import "../../../../styles/constants/colors";

.SmartStudentTasksWrapper {
  width: 100%;
  padding: 40px 0;

  .SmartStudentTasksRow {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    .SmartStudentTasksTitleColumn {
      width: 20%;
      padding: 10px;

      @media #{$smart-mobile} {
        width: 90%;
      }

      a {
        color: $smart-link-color-normal;

        &:hover {
          color: $smart-link-color-darker;
        }
      }
    }

    .SmartStudentTasksTitleEmptyColumn {
      width: 20%;
      padding: 10px;

      @media #{$smart-mobile} {
        width: 10%;
      }
    }

    .SmartStudentTasksDataColumn {
      display: flex;
      width: 20%;
      padding: 10px;
      text-transform: capitalize;

      @media #{$smart-mobile} {
        width: 50%;
      }
    }
  }
}
