@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";

.tablePaginationWrapper {
  width: 100%;
  height: 60px;
  background: $smart-background-color-lozenge center top;
  color: $smart-text-neutral;
  font-size: 16px;
  font-weight: 400;

  @media #{$smart-mobile} {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tablePaginationRow {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0.8em 0.4em;

    .tablePaginationColStart {
      @media #{$smart-mobile} {
        display: none;
      }

      display: flex;
      width: 50%;
      min-width: 5%;
      flex-grow: 0;
    }

    .tablePaginationColEnd {
      @media #{$smart-mobile} {
        width: 100%;
        align-items: center;
        justify-content: center;
      }

      display: flex;
      width: 50%;
      min-width: 5%;
      flex-grow: 0;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        margin: 0;
        -webkit-appearance: none;
      }

      input[type=number] {
        width: 2em;
        min-width: 2em;
        height: 2em;
        border: 1px solid $color-white;
        -moz-appearance: textfield;
        background: transparent;
        color: $smart-text-neutral;
        text-align: center;
      }
    }
  }

  .tablePaginationNavContainer {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;

    @media #{$smart-mobile} {
      display: flex;
      width: 70%;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  .tablePaginationBackToTopBtnContainer {
    display: none;

    @media #{$smart-mobile} {
      display: flex;
      width: 30%;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  .tablePaginationBackToTopBtn {
    &:hover,
    &:active {
      border-color: $smart-text-neutral;
      background: none;
    }
  }

  .tablePaginationBackToTopBtnIcon {
    width: 40px !important;
    height: 40px !important;
    transform: rotate(90deg);
  }

  .tablePaginationDisplayPages {
    z-index: 1;
  }

  .tablePaginationBtnWrapper {
    &.tablePaginationHideBtn {
      display: none;
    }

    .tablePaginationBtn {
      &.tablePaginationHideLeftBtn {
        -webkit-animation: slide-out-arrow-left 0.4s forwards;
        animation: slide-out-arrow-left 0.4s forwards;
      }

      &.tablePaginationHideRightBtn {
        -webkit-animation: slide-out-arrow-right 0.4s forwards;
        animation: slide-out-arrow-right 0.4s forwards;
      }

      @keyframes slide-out-arrow-left {
        100% {
          transform: translateX(50px);
          visibility: hidden;
        }
      }

      @media #{$smart-mobile} {
        @keyframes slide-out-arrow-left {
          50% { transform: translateX(-50px); }

          100% {
            position: fixed;
            left: -50px;
            visibility: hidden;
          }
        }
      }

      @-webkit-keyframes slide-out-arrow-left {
        100% {
          -webkit-transform: translateX(50px);
          visibility: hidden;
        }
      }

      @media #{$smart-mobile} {
        @-webkit-keyframes slide-out-arrow-left {
          50% { -webkit-transform: translateX(-50px); }

          100% {
            position: fixed;
            left: -50px;
            visibility: hidden;
          }
        }
      }

      @keyframes slide-out-arrow-right {
        /* slide arrow to the right, make it invisible when it is out of sight and prevent horizontal scrolling */
        100% {
          position: fixed;
          transform: translateX(50px);
          visibility: hidden;
        }
      }

      @media #{$smart-mobile} {
        @keyframes slide-out-arrow-right {
          50% { transform: translateX(-50px); }

          100% {
            position: fixed;
            left: -50px;
            visibility: hidden;
          }
        }
      }

      @-webkit-keyframes slide-out-arrow-right {
        /* slide arrow to the right, make it invisible when it is out of sight and prevent horizontal scrolling */
        100% {
          position: fixed;
          -webkit-transform: translateX(50px);
          visibility: hidden;
        }
      }

      @media #{$smart-mobile} {
        @-webkit-keyframes slide-out-arrow-right {
          50% { -webkit-transform: translateX(-50px); }

          100% {
            position: fixed;
            left: -50px;
            visibility: hidden;
          }
        }
      }

      &.tablePaginationShowLeftBtn {
        -webkit-animation: slide-in-arrow-left 0.4s forwards;
        animation: slide-in-arrow-left 0.4s forwards;
      }

      &.tablePaginationShowRightBtn {
        -webkit-animation: slide-in-arrow-right 0.4s forwards;
        animation: slide-in-arrow-right 0.4s forwards;
      }

      @keyframes slide-in-arrow-left {
        0% { transform: translateX(50px); }
        100% { transform: translateX(0); }
      }

      @media #{$smart-mobile} {
        @keyframes slide-in-arrow-left {
          0% { transform: translateX(-50px); }
          100% { transform: translateX(0); }
        }
      }

      @-webkit-keyframes slide-in-arrow-left {
        0% { -webkit-transform: translateX(50px); }
        100% { -webkit-transform: translateX(0); }
      }

      @media #{$smart-mobile} {
        @-webkit-keyframes slide-in-arrow-left {
          0% { -webkit-transform: translateX(50px); }
          100% { -webkit-transform: translateX(0); }
        }
      }

      @keyframes slide-in-arrow-right {
        0% { transform: translateX(50px); }
        100% { transform: translateX(0); }
      }

      @media #{$smart-mobile} {
        @keyframes slide-in-arrow-right {
          0% { transform: translateX(-50px); }
          100% { transform: translateX(0); }
        }
      }

      @-webkit-keyframes slide-in-arrow-right {
        0% { -webkit-transform: translateX(50px); }
        100% { -webkit-transform: translateX(0); }
      }

      @media #{$smart-mobile} {
        @-webkit-keyframes slide-in-arrow-right {
          0% { -webkit-transform: translateX(-50px); }
          100% { -webkit-transform: translateX(0); }
        }
      }

      width: 2em;
      height: 2em;
      margin: 0 0.8em;
      background: $color-white;
      border-radius: 50%;

      .tablePaginationChevron {
        display: block;
        width: 1em;
        height: 1em;
        margin: auto;
      }
    }
  }
}
