@import "../../styles/constants/colors";

.wrapper {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid $smart-card-border-color;
  margin-bottom: 1px;
  background-color: $color-white;
  color: var(--text-neural);
  font-size: 16pt;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .text {
    display: flex;
    flex-grow: 3;
    align-items: center;
  }

  .component {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.iconWrapper {
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 18px;
  height: 18px;
  transform: rotate(90deg);
  transition: transform 500ms;
}

.iconOpen {
  transform: rotate(-90deg);
}

.content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 500ms;
}

.contentOpen {
  max-height: 2000px;
}
