@import '../../../styles/constants/colors';

.smartTableHeaderSortableCellWrapper {
  display: flex;
  height: 40px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 10px;
}

.smartTableHeaderSortableCellIconsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.smartTableHeaderSortableCellIcons {
  width: 20px;
  height: 20px;
}

.smartTableHeaderSortableCellSort {
  border-left: 1px solid rgba($color: $smart-text-neutral, $alpha: 0.5);
}

.smartTableHeaderSortableCellTextLeft {
  text-align: left;
}

.smartTableHeaderSortableCellTextCenter {
  text-align: center;
}

.smartTableHeaderSortableCellTextRight {
  text-align: right;
}

.smartTableHeaderSortableCellFirst {
  border-left: none;
}

.smartTableHeaderSortableCellLast {
  border-right: 1px solid rgba($color: $smart-text-neutral, $alpha: 0.5);
}

.smartTableHeaderSortableCellDashed {
  border-left-style: dashed;
}

.smartTableHeaderSortableCellLastDashed {
  border-right-style: dashed;
}
