@import "../../styles/constants/colors";

.wrapper {
  display: flex;
  align-items: center;
}

.container {
  position: relative;
  display: block;
  width: 56px;
  padding: 3px;
  margin: 0 10px;
  cursor: pointer;

  .input {
    position: absolute;
    opacity: 0;
  }

  .slider {
    position: relative;
    display: block;
    height: 25px;
    border-radius: 18px;
    transition: all 0.15s ease-out 0s;
  }

  .input:checked ~ .handler {
    left: 55%;
  }

  .handler {
    position: absolute;
    top: 5px;
    left: 5.5px;
    width: 20px;
    height: 20px;
    background-color: $color-white;
    border-radius: 10px;
    transition: left 0.15s ease-out 0s;
  }
}
