@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";

.secondLevelTabsWrapper {
  display: flex;
  width: 100%;
  min-height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  background-color: $color-white;
  color: $smart-text-neutral;

  @media #{$smart-mobile} {
    padding: 0 10px;
  }
}

.secondLevelTabsWrapperTabs {
  display: flex;
  align-items: center;

  @media #{$smart-mobile} {
    padding: 0 10px;
  }

  div {
    &:first-child {
      margin-left: 0;
    }
  }
}

.secondLevelTabsWrapperActions {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media #{$smart-mobile} {
    padding: 0 10px;
  }
}

.secondLevelTabsWrapperActionsItem {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.secondLevelTabsWrapperFilterIcon {
  width: 40px;
  height: 40px;
  padding-top: 2px;
  padding-left: 8px;
}

.secondLevelTabsStoryWrapperComponent {
  padding: 40px;

  @media #{$smart-mobile} {
    padding: 0;
  }
}

.secondLevelTabsWrapperInfoIcon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
