@import "../../../styles/constants/colors";

.wrapper {
  display: flex;
  height: 40px;
  margin-bottom: 10px;

  input[type="radio"] {
    display: none;

    + *::before {
      display: inline-block;
      width: 20px;
      height: 20px;
      border-width: 0.1rem;
      border-style: solid;
      border-color: $smart-text-neutral;
      margin-right: 20px;
      border-radius: 50%;
      content: "";
      vertical-align: bottom;
    }

    &:checked + *::before {
      border-color: $smart-text-neutral;
      background: radial-gradient($smart-text-neutral 0%, $smart-text-neutral 40%, transparent 50%, transparent);
    }
  }

  .radioLabel {
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:first-child) {
      margin-top: unset;
    }

    &:not(:last-child) {
      margin-bottom: unset;
    }
  }
}
