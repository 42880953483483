@import "../../styles/constants/colors";

.wrapper {
  display: inline-block;
}

.headerLeft,
.headerRight {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: $smart-text-neutral;
  cursor: pointer;
  font-size: 16px;

  div {
    &:first-child {
      margin-right: 5px;
    }
  }
}

.headerLeft {
  justify-content: flex-start;
}

.headerRight {
  justify-content: flex-end;
}

.filterIcon {
  width: 12px;
  height: 12px;
  margin-left: 10px;
}

.dropdown {
  position: absolute;
  z-index: 100;
  min-width: 200px;
  border: 1px solid $smart-border-dropdown;
  background-color: $color-white;
  border-radius: 5px;
  box-shadow: 0 3px 7px 0 $contrast-light-box-shadow;
  color: $smart-text-neutral;
}

.dropdown::after,
.dropdown::before {
  position: absolute;
  bottom: 100%;
  left: 15px;
  width: 0;
  height: 0;
  border: solid transparent;
  content: " ";
  pointer-events: none;
}

.dropdown::after {
  right: 16px;
  border-width: 10px;
  border-color: $color-black;
  border-bottom-color: $color-white;
  background-clip: padding-box;
}

.dropdown::before {
  border-width: 10.5px;
  border-color: #b8b8b800;
  border-bottom-color: map-get($background-and-border-colors, icon);
  background-clip: padding-box;
}

.dropdownRight::after,
.dropdownRight::before {
  right: 15px;
  left: auto;
}

.dropdownOption {
  position: relative;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid $smart-background-color-lozenge;
  cursor: pointer;

  &:hover {
    background-color: $smart-dropdown-option-hover;
  }

  &:last-child {
    border-bottom: none;
  }
}

.checkIconWrapper {
  width: 40px;
  height: 40px;
}

.activeOption {
  font-weight: 600;
}

.inactiveOption {
  font-weight: normal;
}

.arrowLeft,
.arrowRight {
  position: absolute;
  z-index: -1;
  top: -10px;
  width: 20px;
  height: 20px;
  background-color: $color-white;
  box-shadow: 0 3px 7px 0 $contrast-light-box-shadow;
  transform: rotate(45deg);
}

.open {
  opacity: 1;
  // display: block;
  transition: all 0.1s ease-in-out;
  visibility: visible;
}

.closed {
  opacity: 0;
  // display: none;
  transition: all 0.1s ease-in-out;
  visibility: hidden;
}
