@import "../../../../styles/constants/breakpoints";

.tableContainerFixedWidth {
  @media #{$smart-desktop} {
    max-width: fit-content;
  }
}

.tableContainerFullWidth {
  display: flex;
  flex-direction: column;
}

.tableContainerFixedWidth,
.tableContainerFullWidth {
  @media #{$smart-desktop} {
    display: flex;
    height: 538px;
    max-height: 560px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

.tableWrapper {
  @media #{$smart-desktop} {
    display: flex;
    max-width: 1024px;
    justify-content: center;
    margin: 0 auto;
  }
}
