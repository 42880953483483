@import "../../styles/constants/colors";

.contentWrapper {
  width: 100%;
  max-width: 500px;
  height: 100%;
  overflow-y: auto;

  .aditionalContent {
    padding: 20px;
    background: $smart-background-color-lozenge;
  }

  .contentUnder {
    padding: 20px;
    background: $color-white;
  }
}
